import styled from "@emotion/styled";
import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  LanguagePolisku,
  aesDecrypt,
  aesEncrypt,
  useAuth,
  useAxios,
} from "@pru-cnp/ui-polisku";
import { WITHDRAWAL_HOST } from "../root.component";

import Checked from "../assets/checked.svg";

const SITE_KEY = "6LcddxkpAAAAAJZFSxIyuKBCeqU90lLPav8VofiI";

function Persetujuan() {
  const { echo } = LanguagePolisku();
  const { logout } = useAuth();
  const userStorage = window.localStorage.getItem("user");
  const user =
    !userStorage || userStorage === "null"
      ? userStorage
      : JSON.parse(aesDecrypt(window.localStorage.getItem("user")));
  const { state } = useLocation();
  const navigate = useNavigate();
  const [language] = React.useState(
    window.localStorage.getItem("language") || "id"
  );
  const [withdrawalForm] = useState(
    JSON.parse(aesDecrypt(window.localStorage.getItem("withdrawalForm"))) || {}
  );
  const [image, setImage] = useState("");
  const [confirm1, setConfirm1] = useState(false);
  const [confirm2, setConfirm2] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      function () {}
    );

    if (state?.cvp) {
      const body = {
        pol_no: withdrawalForm?.chosenPolicy?.policy_no,
        trigger_id: state?.transactionId
      };

      const intervalId = setInterval(() => {
        useAxios({
          method: "post",
          url: `${WITHDRAWAL_HOST}/cvp-result`,
          body,
        })
          .then((response) => {
            if (response) {
              const { status, data } = response;
              if (status == 200) {
                if (data?.resp_code == 0) {
                  const pdfBlob = convertBase64ToBlob(data?.file_base64);
                  const pdfBlobUrl = URL.createObjectURL(pdfBlob);
                  setImage(pdfBlobUrl);
                  const cvpDoc = {
                    category: `cvp_${
                      withdrawalForm?.chosenPolicy?.is_sharia === "No"
                        ? "conven"
                        : "sharia"
                    }`,
                    file_name: state.transactionId+".pdf",
                    content: data?.file_base64,
                  };
                  withdrawalForm.cvp = cvpDoc;
                  window.localStorage.setItem(
                    "withdrawalForm",
                    aesEncrypt(JSON.stringify(withdrawalForm))
                  );
                  clearInterval(intervalId);
                  setLoading(false);
                }
              } else if (status === 401) {
                alert(echo("session-expired"));
                logout();
                clearInterval(intervalId);
                setLoading(false);
              }
            }
          })
          .catch(() => {
            // Handle error if needed
          });
      }, 10000);

      return () => {
        clearInterval(intervalId);
      };
    } else {
      setLoading(false);
    }
  }, []);

  const handleConfirm1 = () => {
    setConfirm1(!confirm1);
  };

  const handleConfirm2 = () => {
    setConfirm2(!confirm2);
  };

  const handleLanjutkan = async () => {
    if (!confirm1 || !confirm2) {
      alert(echo("please-approve-first"));
      return;
    }
    if (
      (withdrawalForm?.chosenPolicy?.currency === "IDR" &&
        withdrawalForm?.nominalPenarikan >= 25000000) ||
      (withdrawalForm?.chosenPolicy?.currency === "USD" &&
        withdrawalForm?.nominalPenarikan >= 2500)
    ) {
      const captcha = await grecaptcha.execute(SITE_KEY, { action: "submit" });
      const purpose = "trx";
      useAxios({
        method: "post",
        url: "/auth-request",
        body: {
          method: "face", captcha, purpose
        },
      }).then((response) => {
        if (response) {
          const { status, data } = response;
          if (status == 200) {
            if (data.resp_code == 0) {
              if (data?.liveness_url) window.location.href = data?.liveness_url;
            } else {
              const responseError =
                language === "id"
                  ? data.resp_desc?.id
                  : data.resp_desc?.en;
              alert(responseError || "Terjadi kesalahan.");
            }
          } else {
            const responseError =
              language === "id"
                ? data.resp_desc?.id
                : data.resp_desc?.en;
            alert(responseError || "Terjadi kesalahan.");
          }
        }
      });
    } else {
      navigate("/contact", {
        state: {
          isAction: true,
        },
      });
    }
  };
  return (
    <div
      style={{ backgroundColor: "#f7f7f7", padding: "26px", heigth: "100vh" }}
    >
      <Div>
        <Div2>{echo("approval")}</Div2>
        <Div3>{echo("withdrawal-and-projection")}</Div3>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
              flexDirection: "column",
            }}
          >
            <CircularProgress
              style={{
                margin: "0px auto",
                display: "block",
              }}
            />
            <div
              style={{
                fontFamily: "Open Sans, sans-serif",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24px",
                padding: "20px",
              }}
            >
              {echo("loading-pdf")}
            </div>
          </Box>
        ) : (
          <>
            {image !== "" && (
              <iframe
                src={image}
                width="100%"
                height="600px"
                title="PDF Viewer"
              ></iframe>
            )}
          </>
        )}
        <Div4>
          <Div5 onClick={handleConfirm1}>
            {confirm1 && (
              <img style={{ height: "24px", width: "24px" }} src={Checked} />
            )}
          </Div5>
          <Div6>{echo("i-understand-and-understand-the-impact")}</Div6>
        </Div4>
        <Div4>
          <Div5 onClick={handleConfirm2}>
            {confirm2 && (
              <img style={{ height: "24px", width: "24px" }} src={Checked} />
            )}
          </Div5>
          <Div6
            sx={{
              fontColor: "gray",
              opacity: 0.6,
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "24px",
              padding: "10px",
              paddingTop: "20px",
            }}
            onClick={() => navigate("/terms-and-conditions")}
          >
            <span
              style={{
                color: "red",
                cursor: "pointer",
                fontWeight: 600,
              }}
            >
              Syarat dan ketentuan{" "}
            </span>
            Penarikan Dana.
          </Div6>
        </Div4>
        <Div7
          style={{
            marginBottom: image === "" ? "40vh" : "",
            marginTop: "30px",
            cursor: "pointer",
          }}
          onClick={handleLanjutkan}
        >
          {echo("agree-and-continue")}
        </Div7>
      </Div>
    </div>
  );
}

export default Persetujuan;

const convertBase64ToBlob = (base64Data) => {
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/pdf" });
  return blob;
};

const Div = styled.div`
  display: flex;
  width: 80vw;
  flex-direction: column;
  font-size: 36px;
  color: #000;
  margin: 0 auto;
`;

const Div2 = styled.div`
  font-family: Open Sans, sans-serif;
  font-weight: 700;
  line-height: 133%;
  align-self: start;
`;

const Div3 = styled.div`
  font-family: Open Sans, sans-serif;
  font-weight: 300;
  line-height: 48px;
  align-self: start;
  margin-top: 10px;
`;

const Img = styled.img`
  aspect-ratio: 0.69;
  object-fit: auto;
  object-position: center;
  width: 100%;
  margin-top: 20px;
`;

const Img2 = styled.img`
  aspect-ratio: 0.71;
  object-fit: auto;
  object-position: center;
  width: 100%;
  margin-top: 20px;
`;

const Div4 = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 12px;
  font-size: 16px;
  color: var(--Primary-Color-Black, #000);
  font-weight: 400;
  line-height: 24px;
  padding: 0 20px;
`;

const Div5 = styled.div`
  border-radius: 4px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0.08);
  border-style: solid;
  border-width: 2px;
  background-color: var(--Primary-Color-White, #fff);
  min-height: 24px;
  min-width: 24px;
  width: 24px;
  height: 24px;
`;

const Div6 = styled.div`
  font-family: Open Sans, sans-serif;
`;

const Div7 = styled.div`
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: var(--Primary-Color-Prudential-Red, #ed1b2e);
  margin-top: 10px;
  color: var(--Primary-Color-White, #fff);
  text-align: center;
  padding: 12px 20px;
  font: 600 18px/156% Open Sans, sans-serif;
  width: max-content;
  @media (max-width: 768px) {
    width: 70vw;
    align-self: center;
  }
`;
