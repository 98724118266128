import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardMedia,
  CircularProgress,
  Typography,
  useMediaQuery,
  styled,
} from "@mui/material";
import styles from "./Home.module.css";
import { useAuth } from "../../hooks/useAuth";
import { useAxios } from "../../hooks/useAxios";
import { aesDecrypt, aesEncrypt } from "../../hooks/useLocalStorage";
import Language from "../../components/Language";

import pieLogo from "../../assets/Icon/pie-chart-outline.png";
import archiveLogo from "../../assets/Icon/archive-outline.svg";
import timeLogo from "../../assets/Icon/time-outline.png";
import personLogo from "../../assets/Icon/person-circle-outline.png";

const WithdrawalColumn = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "start",
  fontSize: 12,
  color: theme.palette.text.primary,
  flexWrap: "nowrap",
  fontWeight: 600,
  lineHeight: "150%",
  cursor: "pointer",
  "& img": {
    width: 40,
    aspectRatio: 1.05,
    objectFit: "auto",
    objectPosition: "center",
    alignSelf: "center",
  },
  "& div": {
    fontFamily: "Open Sans-Regular, Helvetica",
    textAlign: "center",
    marginTop: "8px",
  },
}));

export const Produk = ({
  produkName,
  policyNumber,
  policyStatus,
  productCode,
}) => {
  const productImage = () => {
    try {
      const image = require(`../../assets/images/Products/${productCode}.png`);
      return image;
    } catch (error) {
      const image = require("../../assets/images/imgRectangle.png");
      return image;
    }
  };

  return (
    <Card
      elevation={0}
      sx={(theme) => ({
        alignSelf: "flex-start",
        alignItems: "flex-start",
        backgroundColor: "#ffffff",
        borderColor: "#0000001a",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        flex: "0 0 32.3%",
        paddingRight: 0,
        gap: "4px",
        justifyContent: "center",
        width: "100%",
        boxShadow: "0px 26px 30px 0px rgba(0, 0, 0, 0.05)",
        [theme.breakpoints.up("md")]: {
          flexDirection: "column",
          flex: "0 0 32.3%",
          paddingRight: 0,
        },
      })}
    >
      <CardMedia
        height="200"
        component="img"
        alt="Rectangle"
        image={productImage()}
      />
      <Box
        sx={(theme) => ({
          alignItems: "flex-start",
          display: "flex",
          flex: 1,
          flexDirection: "column",
          flexGrow: 1,
          gap: "2px",
          padding: "20px",
          position: "relative",
        })}
      >
        <Typography
          sx={(theme) => ({
            color: "#333333",
            fontFamily: "Open Sans-Bold, Helvetica",
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "28px",
          })}
        >
          {produkName}
        </Typography>
        <div className={styles.div_status}>
          <span className={styles.text_wrapper}>{policyNumber} | Status: </span>
          <span
            style={{
              color: `${
                policyStatus === "IF" || policyStatus === "In Force"
                  ? "#26c022"
                  : "#F04E1B"
              } `,
              fontFamily: "Noto Sans-Bold, Helvetica",
              fontWeight: 700,
            }}
          >
            {policyStatus === "IF" || policyStatus === "In Force"
              ? "Aktif"
              : "Tidak Aktif"}
          </span>
        </div>
      </Box>
    </Card>
  );
};

export const Home = () => {
  const language = window.localStorage.getItem("language") || "id";
  const { echo } = Language();
  const deviceID = window.localStorage.getItem("deviceID");
  const clientWidth = document.documentElement.clientWidth;
  const userStorage = window.localStorage.getItem("user");
  const user =
    !userStorage || userStorage === "null"
      ? userStorage
      : JSON.parse(aesDecrypt(window.localStorage.getItem("user")));
  const { logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isDisabledQuestionnaire, setIsDisabledQuestionnaire] = useState(false);

  const navigate = useNavigate();
  if (user === "null" || !user) {
    navigate("/login", { replace: true });
  }

  const handleWithdrawal = () => {
    useAxios({
      method: "post",
      url: "/check-ekyc",
    }).then((response) => {
      if (response) {
        const { status, data } = response;
        if (status == 200) {
          setLoading(false);
          if (data?.resp_code == 0) {
            navigate("/withdrawal");
          } else {
            const responseError =
              language === "id"
                ? data.resp_desc?.id
                : data.resp_desc?.en;
            setIsFailed(true);
            setErrorMessage(responseError);
          }
        } else {
          const responseError =
            language === "id"
              ? response?.data.resp_desc?.id
              : response?.data.resp_desc?.en;
          setIsFailed(true);
          setErrorMessage(responseError);
        }
        setLoading(false);
      }
      setLoading(false);
    });
  };

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: "#f7f7f7",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        height: "100%",
        paddingBottom: "24vh",
      })}
      className={styles.home}
    >
      <Box>
        <Box
          sx={(theme) => ({
            display: "inline-flex",
            flexDirection: "column",
            alignItems: "center",
            paddingLeft: "20px",
            paddingRight: "20px",
            gap: "16px",
            [theme.breakpoints.up("md")]: {
              width: "90vw",
            },
          })}
        >
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
              gap: "2px",
              marginTop: "25px",
              [theme.breakpoints.up("md")]: {
                width: "90vw",
              },
            })}
          >
            <Typography
              sx={(theme) => ({
                color: "#333333",
                fontFamily: "Open Sans-Bold, Helvetica",
                fontSize: "12px",
                fontWeight: 600,
                [theme.breakpoints.up("md")]: {
                  fontSize: "16px",
                  lineHeight: "24px",
                },
              })}
            >
              {echo("welcome")}
            </Typography>
            <Typography
              sx={(theme) => ({
                color: "#333333",
                fontFamily: "Open Sans-Bold, Helvetica",
                fontSize: "36px",
                fontWeight: 700,
                marginBottom: "16px",
                [theme.breakpoints.up("md")]: {
                  fontSize: "48px",
                  lineHeight: "48px",
                },
              })}
            >
              {user?.session?.client_data?.policy_holder_name}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            alignSelf="stretch"
            borderRadius="8px"
            boxShadow="0px 26px 30px 0px rgba(0, 0, 0, 0.05)"
            bgcolor="background.paper"
            sx={(theme) => ({
              [theme.breakpoints.down("lg")]: {
                width: "80vw",
              },
            })}
            gap={1.5}
            p={3}
            marginBottom={3}
          >
            <WithdrawalColumn
              onClick={() => navigate("/risk-profile/questionnaire")}
            >
              <img src={pieLogo} alt="Risk Profile icon" />
              <div>{echo("risk-profile")}</div>
            </WithdrawalColumn>
            <WithdrawalColumn onClick={() => navigate("/withdrawal")}>
              <img src={archiveLogo} alt="Withdrawal icon" />
              <div>{echo("form-withdrawal")}</div>
            </WithdrawalColumn>
            <WithdrawalColumn onClick={() => navigate("/withdrawal/history")}>
              <img src={timeLogo} alt="Transaction History icon" />
              <div>{echo("transaction-history")}</div>
            </WithdrawalColumn>
            {/* <WithdrawalColumn
              onClick={() =>
                navigate("/contact", {
                  state: {
                    isHome: true,
                  },
                })
              }
            >
              <img src={personLogo} alt="Contact icon" />
              <div>Change Contact</div>
            </WithdrawalColumn> */}
          </Box>
          <div style={{ display: "none" }} className={styles.riskprofile_div}>
            <div className={styles.riskprofile_txt}>
              <Typography
                sx={(theme) => ({
                  alignSelf: "stretch",
                  color: "#333333",
                  fontFamily: "Open Sans-Bold, Helvetica",
                  fontSize: "18px",
                  fontWeight: 800,
                  marginTop: "-1px",
                  [theme.breakpoints.up("md")]: {
                    fontSize: "20px",
                    lineHeight: "28px",
                  },
                })}
              >
                {echo("check-risk-profile")}
              </Typography>
              <Typography
                sx={(theme) => ({
                  alignSelf: "stretch",
                  color: "#333333",
                  fontFamily: "Open Sans-Bold, Helvetica",
                  fontSize: "16px",
                  fontWeight: 300,
                  marginY: clientWidth < 600 ? 2 : 1,
                  lineHeight: "18px",
                  marginRight: 2,
                  [theme.breakpoints.up("md")]: {
                    fontSize: "14px",
                    lineHeight: "22px",
                  },
                })}
              >
                {echo("find-matched-risk-profile")}
              </Typography>
            </div>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                disabled={isDisabledQuestionnaire}
                sx={(theme) => ({
                  fontStyle: "normal",
                  fontFamily: "Open Sans-Bold, Helvetica",
                  textTransform: "none",
                  backgroundColor: isDisabledQuestionnaire
                    ? "#D8D8D8"
                    : "#ed1a2d",
                  borderRadius: "20px",
                  padding: "10px 16px 11px",
                  width: clientWidth < 600 ? "100%" : "160px",
                  "&:hover": {
                    backgroundColor: "#ed1a2d",
                    boxShadow: "0 12px 8px -2px #D3d3d3",
                  },
                })}
                onClick={() => {
                  localStorage.removeItem("currentNo");
                  localStorage.removeItem("results");
                  navigate("/risk-profile/questionnaire");
                }}
              >
                <Typography
                  sx={(theme) => ({
                    alignSelf: "stretch",
                    color: isDisabledQuestionnaire ? "#808080" : "#ffffff",
                    fontFamily: "Open Sans-Bold, Helvetica",
                    fontSize: "12px",
                    fontWeight: 700,
                    marginTop: "-1px",
                    width: "fit-content",
                    textAlign: "center",
                    [theme.breakpoints.up("md")]: {
                      fontSize: "14px",
                    },
                  })}
                >
                  {echo("continue")}
                </Typography>
              </Button>
            )}
          </div>
          <div className={styles.produk_div}>
            <Typography
              sx={(theme) => ({
                alignSelf: "stretch",
                color: "#333333",
                fontFamily: "Open Sans-Bold, Helvetica",
                fontSize: "12px",
                fontWeight: 400,
                letterSpacing: "0.6px",
                [theme.breakpoints.up("md")]: {
                  fontSize: "14px",
                  lineHeight: "22px",
                },
              })}
            >
              {user?.session?.client_data?.policies ? (
                `${echo("you-have")} ${
                  user?.session?.client_data?.policies?.length
                } ${echo("paydi-policy-insurance")}`
              ) : loading ? (
                <CircularProgress />
              ) : null}
            </Typography>
            <div className={styles.produk_list}>
              {user?.session?.client_data?.policies?.map((item, index) => (
                <Produk
                  key={index}
                  className={styles.frame_11}
                  produkName={item?.product}
                  policyNumber={item?.policy_no}
                  policyStatus={item?.policy_status}
                  productCode={item?.product_code}
                />
              ))}
            </div>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
