import React, { useEffect } from "react";
import { useAxios } from "../../hooks/useAxios";
function LivenessResult() {
    useEffect(() => {
        const body={local:true};
        useAxios({
        method: "get",
        url: "/liveness-result",
        title: "check-ekyc",
        body: body,
        
        }).then((resp)=>{
            try {
                const { status, data } = resp;
                if(data?.purpose=="trx"){
                    window.location.href = "/withdrawal/submit";
                } else {
                    if (status == 200 && data?.resp_code == 0) {
                        window.location.href = "/create-pin";
                    } else {
                        window.location.href = "/";
                    }
                }
            } catch (error) {
                window.location.href = "/";
            }
        })    
    }, []);
    return (
        <div style={{height:400, margin:10, marginTop:10}}>
            please wait...
        </div>
    );
}

export default LivenessResult;