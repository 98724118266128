import styled from "@emotion/styled";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  aesDecrypt,
  LanguagePolisku,
  useAuth,
  useAxios
} from "@pru-cnp/ui-polisku";
import { Dropdown } from "../../components/Dropdown";
import TextInput from "../../components/TextInput";

import CheckUploaded from "../../assets/check-uploaded.svg";
import Checked from "../../assets/checked.svg";
import Delete from "../../assets/delete.svg";
import Plus from "../../assets/plus.svg";
import Upload from "../../assets/upload.svg";

import {
  bentukPembayaran,
  jenisDokumen,
  jenisMataUang,
  namaBank,
} from "./options";

import { getBase64 } from "../../components/utils";

function BentukPembayaran({ withdrawalForm, setWithdrawalForm }) {
  const userStorage = window.localStorage.getItem("user");
  const user =
    !userStorage || userStorage === "null"
      ? userStorage
      : JSON.parse(aesDecrypt(window.localStorage.getItem("user")));
  const [language] = React.useState(
    window.localStorage.getItem("language") || "id"
  );
  const { logout } = useAuth();
  const { echo } = LanguagePolisku();
  const { state } = useLocation();
  const fileInputRef = useRef(null);
  const newFileInputRef = useRef(null);
  const [pakaiRekeningLain, setPakaiRekeningLain] = useState(
    withdrawalForm?.pakaiRekeningLain ||
      withdrawalForm?.rekeningUtama?.bankCode === ""
      ? true
      : false
  );
  const [polisUtama, setPolisUtama] = useState(
    withdrawalForm?.premi ? withdrawalForm?.premi[0] : {}
  );
  const [newPolis, setNewPolis] = useState({});
  const [additionalPolis, setAdditionalPolis] = useState([
    ...(withdrawalForm?.premi?.slice(1) || []),
  ]);
  const [dokumen, setDokumen] = useState({});
  const [newDokumen, setNewDokumen] = useState({});
  const [additionalDokumen, setAdditionalDokumen] = useState([
    ...(withdrawalForm?.dokumen?.slice(1) || []),
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);

  const handleClickPakaiRekeningLain = () => {
    setPakaiRekeningLain(!pakaiRekeningLain);
  };

  useEffect(() => {
    useAxios({
      method: "get",
      url: "/country-list",
    }).then((response) => {
      if (response) {
        const { status, data } = response;
        if (status == 200) {
          const mappedCountryList = data?.countries
            .filter((negara) => negara.id !== "")
            .map((negara) => ({
              label: negara.description,
              value: negara.id,
            }));
          setCountryList(mappedCountryList);
        } else {
          if (status === 401) {
            alert(echo("session-expired"));
            logout();
          } else {
            const responseError =
              language === "id"
                ? data.resp_desc?.id
                : data.resp_desc?.en;
            alert(responseError || echo("something-went-wrong"));
          }
        }
      }
    });

    setWithdrawalForm((prev) => ({
      ...prev,
      pakaiRekeningLain,
    }));
  }, [pakaiRekeningLain]);

  const handleDropdownSelect = useCallback((name, selected) => {
    setWithdrawalForm((prev) => ({
      ...prev,
      [name]: selected,
    }));
  }, []);

  const handlePolisUtama = (name, value) => {
    setPolisUtama((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleNewPolis = (name, value, index) => {
    if (withdrawalForm?.premi[index + 1]) {
      const polisObj = withdrawalForm?.premi[index + 1];
      if (name === "noPolis") {
        polisObj.noPolis = value;
      } else {
        polisObj.amount = value;
      }
      setNewPolis(polisObj);
    } else {
      setNewPolis((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (polisUtama?.noPolis?.length > 0 && polisUtama?.amount?.length > 0) {
        setWithdrawalForm((prev) => ({
          ...prev,
          premi: [polisUtama, ...additionalPolis],
        }));
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [polisUtama]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (newPolis.noPolis && newPolis.amount) {
        setWithdrawalForm((prev) => {
          const existingPolis = prev.premi.find(
            (polis) => polis.noPolis === newPolis.noPolis
          );
          if (existingPolis) {
            const updatedPolis = prev.premi.map((polis) => {
              if (polis.noPolis === newPolis.noPolis) {
                return {
                  ...polis,
                  amount: newPolis.amount,
                };
              }
              return polis;
            });
            return {
              ...prev,
              premi: updatedPolis,
            };
          } else {
            return {
              ...prev,
              premi: [...prev.premi, newPolis],
            };
          }
        });
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [newPolis]);

  const handleAddContribution = () => {
    setAdditionalPolis([...additionalPolis, {}]);
  };

  const handleAddDokumenLain = () => {
    setAdditionalDokumen([...additionalDokumen, {}]);
  };

  const handleDeleteContribution = (index) => {
    const updatedPolis = [...additionalPolis];
    updatedPolis.splice(index, 1);
    setAdditionalPolis(updatedPolis);
    setWithdrawalForm((prev) => ({
      ...prev,
      premi: prev.premi.slice(0, -1),
    }));
  };

  const renderKontribusi = () => {
    return (
      <>
        <TitleChosenPembayaran>{echo("premi-payment")}</TitleChosenPembayaran>
        <TextInput
          placeHolder={echo("policy-no")}
          onChange={(value) => handlePolisUtama("noPolis", value)}
          defaultValue={
            withdrawalForm?.premi ? withdrawalForm?.premi[0]?.noPolis : ""
          }
        />
        <TextInput
          placeHolder={echo("amount")}
          type="number"
          onChange={(value) => handlePolisUtama("amount", value)}
          defaultValue={
            withdrawalForm?.premi ? withdrawalForm?.premi[0]?.amount : ""
          }
        />
        {additionalPolis.map((polis, index) => (
          <div key={index}>
            <BottomSeparator />
            <AdditionalPolis key={index}>
              <AdditionalPolisContent>
                <TextInput
                  placeHolder={echo("policy-no")}
                  defaultValue={polis.noPolis}
                  onChange={(value) => handleNewPolis("noPolis", value, index)}
                />
                <TextInput
                  placeHolder={echo("amount")}
                  defaultValue={polis.amount}
                  type="number"
                  onChange={(value) => handleNewPolis("amount", value, index)}
                />
              </AdditionalPolisContent>
              <ImgDelete
                loading="lazy"
                src={Delete}
                onClick={() => handleDeleteContribution(index)}
              />
            </AdditionalPolis>
          </div>
        ))}
        <BottomSeparator />
        <AddContribution
          style={{
            width: "max-content",
            display: additionalPolis.length + 2 > 4 ? "none" : "",
          }}
          onClick={handleAddContribution}
        >
          <AddContributionTitle>{echo("add-new-policy")}</AddContributionTitle>
          <ImgAdd loading="lazy" src={Plus} />
        </AddContribution>
      </>
    );
  };

  const handleRekeningLain = useCallback((name, value) => {
    setWithdrawalForm((prev) => ({
      ...prev,
      rekeningLain: {
        ...prev.rekeningLain,
        [name]: value,
      },
    }));
  }, []);

  const renderTransfer = () => {
    return (
      <>
        <TitleChosenPembayaran>
          {echo("transfer-payment")}
        </TitleChosenPembayaran>
        {(!pakaiRekeningLain && withdrawalForm?.rekeningUtama?.noRekening)? (
          <>
            <Rekening>
              <RekeningBox>
                <RekeningDiv>
                  <RekeningText>
                    {echo("bank-account")}
                    <span>*</span>
                  </RekeningText>
                </RekeningDiv>
                <RekeningDivValue>
                  {withdrawalForm?.rekeningUtama?.noRekening}
                </RekeningDivValue>
              </RekeningBox>
            </Rekening>
            <Rekening>
              <RekeningBox>
                <RekeningDiv>
                  <RekeningText>
                    {echo("bank-account-name")}
                    <span>*</span>
                  </RekeningText>
                </RekeningDiv>
                <RekeningDivValue>
                  {withdrawalForm?.rekeningUtama?.namaPemilik}
                </RekeningDivValue>
              </RekeningBox>
            </Rekening>
            <RekeningBank>
              <RekeningBankBox>
                <RekeningText>{echo("bank-name")}</RekeningText>
                <RekeningDivValue>
                  {withdrawalForm?.rekeningUtama?.bankCode?.label}
                </RekeningDivValue>
              </RekeningBankBox>
            </RekeningBank>
            <StatusRekening>
              <StatusRekeningLabel>{echo("bank-status")}:</StatusRekeningLabel>
              <StatusRekeningValue>Active</StatusRekeningValue>
            </StatusRekening>
            <BottomSeparator />
          </>
        ):(<><Rekening><RekeningText>{(language=="id")?"Tidak ada rekening tersimpan":"No saved account"}</RekeningText></Rekening></>)}
        <KonfirmasiRekeningLain>
          <TitleChosenPembayaran>
            {echo("confirm-other-bank-account")}
          </TitleChosenPembayaran>
          <KonfirmasiRekeningLainBox>
            <KonfirmasiRekeningLainDiv>
              <KonfirmasiRekeningLainCheckboxDiv>
                <KonfirmasiRekeningLainCheckbox
                  onClick={() => handleClickPakaiRekeningLain()}
                >
                  {pakaiRekeningLain && <CheckedIcon src={Checked} />}
                </KonfirmasiRekeningLainCheckbox>
              </KonfirmasiRekeningLainCheckboxDiv>
              <KonfirmasiRekeningLainText>
                {echo("use-other-bank-account")}
              </KonfirmasiRekeningLainText>
            </KonfirmasiRekeningLainDiv>
          </KonfirmasiRekeningLainBox>
        </KonfirmasiRekeningLain>
        {pakaiRekeningLain && (
          <>
            <TextInput
              placeHolder={echo("bank-account")}
              defaultValue={withdrawalForm?.rekeningLain?.noRekening}
              onChange={(value) => handleRekeningLain("noRekening", value)}
              max={35}
              isNumber={true}
            />
            <TextInput
              style={{ marginBottom: 10 }}
              defaultValue={withdrawalForm?.rekeningLain?.namaPemilik}
              placeHolder={echo("bank-account-name")}
              onChange={(value) => handleRekeningLain("namaPemilik", value)}
            />
            <Dropdown
              placeholder={echo("bank-name")}
              isNoTranslate={true}
              defaultValue={withdrawalForm?.rekeningLain?.namaBank}
              options={namaBank}
              onSelect={(selected) => handleRekeningLain("namaBank", selected)}
            />
            <TextInput
              style={{ marginTop: 0, marginBottom: 10 }}
              defaultValue={withdrawalForm?.rekeningLain?.cabangUnit}
              placeHolder={echo("bank-branch")}
              required={true}
              onChange={(value) => handleRekeningLain("cabangUnit", value)}
            />
            {/* <TextInput
              style={{ marginBottom: 10 }}
              defaultValue={withdrawalForm?.rekeningLain?.kota}
              placeHolder={echo("bank-city")}
              required={false}
              onChange={(value) => handleRekeningLain("kota", value)}
            /> */}
            <Dropdown
              placeholder={echo("bank-currency")}
              defaultValue={withdrawalForm?.rekeningLain?.jenisMataUang}
              options={jenisMataUang}
              onSelect={(selected) =>
                handleRekeningLain("jenisMataUang", selected)
              }
            />
            {/* <TextInput
              style={{ marginTop: 0, marginBottom: 10 }}
              defaultValue={withdrawalForm?.rekeningLain?.swiftCode}
              placeHolder={echo("bank-swift-code")}
              required={false}
              onChange={(value) => handleRekeningLain("swiftCode", value)}
            />
            <Dropdown
              placeholder={echo("country")}
              defaultValue={withdrawalForm?.rekeningLain?.negara}
              options={countryList}
              onSelect={(selected) => handleRekeningLain("negara", selected)}
              isNoTranslate={true}
            />
            <TextInput
              style={{ marginTop: 0 }}
              placeHolder="NPWP"
              defaultValue={withdrawalForm?.rekeningLain?.npwp}
              required={false}
              onChange={(value) => handleRekeningLain("npwp", value)}
              max={16}
            /> */}
          </>
        )}
      </>
    );
  };

  const renderChosenPembayaran = () => {
    if (!withdrawalForm || !withdrawalForm.bentukPembayaran) {
      return null;
    }

    const value = withdrawalForm.bentukPembayaran.value;

    if (value === 1) {
      return <ChosenPembayaran>{renderKontribusi()}</ChosenPembayaran>;
    }
    if (value === 2) {
      return <ChosenPembayaran>{renderTransfer()}</ChosenPembayaran>;
    }
    if (value === 3) {
      return (
        <ChosenPembayaran>
          {renderKontribusi()}
          {renderTransfer()}
        </ChosenPembayaran>
      );
    }

    return null;
  };

  const handleClickUpload = () => {
    if (!dokumen?.jenisDokumen) {
      alert(echo("choose-document-type-first"));
      return;
    }
    fileInputRef.current.click();
  };

  const handleChangeFirstDocument = () => {
    if (!withdrawalForm?.dokumen[0]?.jenisDokumen) {
      alert(echo("choose-document-type-first"));
      return;
    }
    setDokumen({
      jenisDokumen: withdrawalForm?.dokumen[0]?.jenisDokumen,
    });
    fileInputRef.current.click();
  };

  const handleClickUploadNewDokumen = (index) => {
    if (!newDokumen?.jenisDokumen) {
      alert(echo("choose-document-type-first"));
      return;
    }
    newFileInputRef.current.click();
  };

  const handleChangeOtherDocument = (index) => {
    if (!withdrawalForm?.dokumen[index]?.jenisDokumen) {
      alert(echo("choose-document-type-first"));
      return;
    }
    setNewDokumen({
      jenisDokumen: withdrawalForm?.dokumen[index]?.jenisDokumen,
    });
    newFileInputRef.current.click();
  };

  const onUploadFile = (e) => {
    setIsLoading(true);
    const file = e.target.files[0];
    if (file.size > 5242880) {
      // 5 MB in bytes
      alert("Maksimum ukuran file adalah 5 MB");
      e.target.value = ""; // clear the file input
      return;
    }
    getBase64(file, (result) => {
      setDokumen((prev) => ({
        ...prev,
        id: 0,
        file: {
          base64: result,
          name: file.name,
          type: file.type,
          size: file.size,
        },
      }));
    });
  };

  const onUploadFileNewDokumen = (e, index) => {
    setIsLoading(true);
    const file = e.target.files[0];
    if (file.size > 5242880) {
      // 5 MB in bytes
      alert("Maksimum ukuran file adalah 5 MB");
      e.target.value = ""; // clear the file input
      return;
    }
    getBase64(file, (result) => {
      setNewDokumen((prev) => ({
        ...prev,
        id: index,
        file: {
          base64: result,
          name: file.name,
          type: file.type,
          size: file.size,
        },
      }));
    });
  };

  useEffect(() => {
    if (dokumen && dokumen?.file && dokumen?.jenisDokumen) {
      if (!withdrawalForm?.dokumen) {
        setWithdrawalForm((prev) => ({
          ...prev,
          dokumen: [dokumen],
        }));
      } else {
        const updatedDokumen = [...withdrawalForm?.dokumen];
        updatedDokumen[0] = dokumen;
        setWithdrawalForm((prev) => ({
          ...prev,
          dokumen: updatedDokumen,
        }));
      }
      if (withdrawalForm?.dokumen?.length > 1) window.location.reload();
    }
  }, [dokumen]);

  useEffect(() => {
    if (
      newDokumen &&
      newDokumen?.id &&
      newDokumen?.file &&
      newDokumen?.jenisDokumen
    ) {
      if (!withdrawalForm?.dokumen) return;
      if (!withdrawalForm?.dokumen[newDokumen.id]) {
        const updatedDokumen = [...withdrawalForm?.dokumen];
        updatedDokumen[newDokumen.id] = newDokumen;
        setWithdrawalForm((prev) => ({
          ...prev,
          dokumen: updatedDokumen,
        }));
      } else {
        setWithdrawalForm((prev) => {
          const existingDokumenIndex = prev?.dokumen?.findIndex(
            (item) => item.id === newDokumen.id
          );
          if (existingDokumenIndex !== -1) {
            const updatedDokumen = [...prev?.dokumen];
            updatedDokumen[existingDokumenIndex] = newDokumen;
            return {
              ...prev,
              dokumen: updatedDokumen,
            };
          } else {
            return {
              ...prev,
              dokumen: [...prev?.dokumen, newDokumen],
            };
          }
        });
      }
      window.location.reload();
    }
  }, [newDokumen]);

  const handleDeleteDokumen = (index) => {
    const updatedDokumen = [...additionalDokumen];
    updatedDokumen.splice(index, 1);
    setAdditionalDokumen(updatedDokumen);
    setWithdrawalForm((prev) => ({
      ...prev,
      dokumen: prev.dokumen.slice(0, -1),
    }));
    window.location.reload();
  };

  return (
    <>
      <TitleStep>{echo("payment-method")}</TitleStep>
      <FormDiv>
        <Dropdown
          placeholder={echo("payment-method")}
          options={bentukPembayaran}
          onSelect={(selected) =>
            handleDropdownSelect("bentukPembayaran", selected)
          }
          defaultValue={withdrawalForm?.bentukPembayaran}
        />
        {renderChosenPembayaran()}
        <UnggahDokumen>
          <TitleChosenPembayaran>
            {echo("upload-document-support")}
          </TitleChosenPembayaran>
          <Dropdown
            placeholder={echo("document-type")}
            options={jenisDokumen}
            defaultValue={
              withdrawalForm?.dokumen
                ? withdrawalForm?.dokumen[0]?.jenisDokumen
                : ""
            }
            onSelect={(selected) =>
              setDokumen({
                id: 0,
                jenisDokumen: selected,
              })
            }
          />
          {withdrawalForm?.dokumen &&
          withdrawalForm?.dokumen[0]?.file?.base64 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flex: 3,
              }}
            >
              {withdrawalForm?.dokumen[0]?.file?.type === "application/pdf" ? (
                <div
                  style={{
                    backgroundColor: "var(--Primary-Color-White, #fff)",
                    fontFamily: '"Open Sans", sans-serif',
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "18px",
                    fontWeight: 300,
                    lineHeight: "156%",
                    justifyContent: "center",
                    padding: "21px 24px",
                    borderRadius: "8px",
                    boxShadow: "0px 26px 30px 0px rgba(0, 0, 0, 0.05)",
                    flex: 2.8,
                  }}
                >
                  <p>{withdrawalForm?.dokumen[0]?.file?.name}</p>
                  <input
                    ref={fileInputRef}
                    type="file"
                    id="ubahDokumen"
                    style={{ display: "none" }}
                    value=""
                    onChange={(e) => onUploadFile(e)}
                  />
                  <UnggahDokumenUploadedText
                    style={{
                      cursor: "pointer",
                      color: "white",
                      width: "max-content",
                      fontSize: 10,
                      padding: 5,
                    }}
                    onClick={handleChangeFirstDocument}
                  >
                    {echo("change-document")}
                  </UnggahDokumenUploadedText>
                </div>
              ) : (
                <UnggahDokumenUploaded style={{ flex: 2.8 }}>
                  <UnggahDokumenUploadedImg
                    loading="lazy"
                    srcSet={withdrawalForm?.dokumen[0]?.file?.base64}
                  />
                  <UnggahDokumenUploadedIcon
                    loading="lazy"
                    src={CheckUploaded}
                  />
                  <input
                    ref={fileInputRef}
                    type="file"
                    id="ubahDokumen"
                    style={{ display: "none" }}
                    value=""
                    onChange={(e) => onUploadFile(e)}
                  />
                  <UnggahDokumenUploadedText
                    style={{ cursor: "pointer" }}
                    onClick={handleChangeFirstDocument}
                  >
                    {echo("change-document")}
                  </UnggahDokumenUploadedText>
                </UnggahDokumenUploaded>
              )}
              <ImgDelete
                style={{
                  flex: 0.2,
                  height: 40,
                  width: 60,
                }}
                loading="lazy"
                src={Delete}
                onClick={() => handleDeleteDokumen(0)}
              />
            </div>
          ) : (
            <UnggahDokumenUpload onClick={handleClickUpload}>
              <input
                ref={fileInputRef}
                type="file"
                id="file-input"
                style={{ display: "none" }}
                value=""
                onChange={(e) => onUploadFile(e)}
              />
              <UnggahDokumenUploadIcon loading="lazy" src={Upload} />
              <UnggahDokumenUploadText>
                {echo("upload-document-support")}
              </UnggahDokumenUploadText>
            </UnggahDokumenUpload>
          )}
          {additionalDokumen?.map((doc, index) => {
            return (
              <div key={index}>
                <Dropdown
                  placeholder={echo("document-type")}
                  options={jenisDokumen}
                  defaultValue={doc ? doc?.jenisDokumen : ""}
                  onSelect={(selected) =>
                    setNewDokumen({
                      id: index + 1,
                      jenisDokumen: selected,
                    })
                  }
                />
                {!isLoading && doc && doc?.file?.base64 ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flex: 3,
                    }}
                  >
                    {doc?.file?.type === "application/pdf" ? (
                      <div
                        style={{
                          backgroundColor: "var(--Primary-Color-White, #fff)",
                          fontFamily: '"Open Sans", sans-serif',
                          display: "flex",
                          flexDirection: "column",
                          fontSize: "18px",
                          fontWeight: 300,
                          lineHeight: "156%",
                          justifyContent: "center",
                          padding: "21px 24px",
                          borderRadius: "8px",
                          boxShadow: "0px 26px 30px 0px rgba(0, 0, 0, 0.05)",
                          flex: 2.8,
                        }}
                      >
                        <p>{doc?.file?.name}</p>
                        <input
                          ref={newFileInputRef}
                          type="file"
                          id="ubahDokumen"
                          style={{ display: "none" }}
                          value=""
                          onChange={(e) => onUploadFileNewDokumen(e, index + 1)}
                        />
                        <UnggahDokumenUploadedText
                          style={{
                            cursor: "pointer",
                            color: "white",
                            width: "max-content",
                            fontSize: 10,
                            padding: 5,
                          }}
                          onClick={() => handleChangeOtherDocument(index + 1)}
                        >
                          {echo("change-document")}
                        </UnggahDokumenUploadedText>
                      </div>
                    ) : (
                      <UnggahDokumenUploaded style={{ flex: 2.8 }}>
                        <UnggahDokumenUploadedImg
                          loading="lazy"
                          srcSet={doc?.file?.base64}
                        />
                        <UnggahDokumenUploadedIcon
                          loading="lazy"
                          src={CheckUploaded}
                        />
                        <input
                          ref={newFileInputRef}
                          type="file"
                          id="ubahDokumen"
                          style={{ display: "none" }}
                          value=""
                          onChange={(e) => onUploadFileNewDokumen(e, index + 1)}
                        />
                        <UnggahDokumenUploadedText
                          style={{ cursor: "pointer" }}
                          onClick={() => handleChangeOtherDocument(index + 1)}
                        >
                          {echo("change-document")}
                        </UnggahDokumenUploadedText>
                      </UnggahDokumenUploaded>
                    )}
                    <ImgDelete
                      style={{
                        flex: 0.2,
                        height: 40,
                        width: 60,
                      }}
                      loading="lazy"
                      src={Delete}
                      onClick={() => handleDeleteDokumen(index + 1)}
                    />
                  </div>
                ) : (
                  <>
                    {((newDokumen?.id === index + 1 &&
                      newDokumen?.jenisDokumen) ||
                      (withdrawalForm?.dokumen &&
                        withdrawalForm?.dokumen[index + 1]?.jenisDokumen)) && (
                      <UnggahDokumenUpload
                        onClick={() => handleClickUploadNewDokumen(index + 1)}
                      >
                        <input
                          ref={newFileInputRef}
                          type="file"
                          id="file-input"
                          style={{ display: "none" }}
                          value=""
                          onChange={(e) => onUploadFileNewDokumen(e, index + 1)}
                        />
                        <UnggahDokumenUploadIcon loading="lazy" src={Upload} />
                        <UnggahDokumenUploadText>
                          {echo("upload-document-support")}
                        </UnggahDokumenUploadText>
                      </UnggahDokumenUpload>
                    )}
                  </>
                )}
              </div>
            );
          })}
          {withdrawalForm?.dokumen &&
            withdrawalForm?.dokumen[0]?.jenisDokumen &&
            withdrawalForm?.dokumen[0]?.file && (
              <AddContribution
                style={{
                  width: "max-content",
                  display: additionalDokumen?.length + 2 > 4 ? "none" : "",
                }}
                onClick={handleAddDokumenLain}
              >
                <AddContributionTitle>
                  {echo("add-other-document")}
                </AddContributionTitle>
                <ImgAdd loading="lazy" src={Plus} />
              </AddContribution>
            )}
        </UnggahDokumen>
      </FormDiv>
    </>
  );
}

export default BentukPembayaran;

const TitleStep = styled.p`
  color: var(--Primary-Color-Black, #000);
  font: 700 24px/32px Open Sans, sans-serif;
  align-self: center;
  margin-top: 40px;
  width: 80vw;
`;

const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80vw;
`;

const ChosenPembayaran = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 300;
  width: 100%;
  margin-top: 24px;
`;

const TitleChosenPembayaran = styled.div`
  color: var(--Primary-Color-Black, #000);
  text-align: start;
  font-family: Open Sans, sans-serif;
  font-weight: 700;
  width: 80vw;
`;

const DefaultPolisBox = styled.div`
  align-items: start;
  border-radius: 8px;
  box-shadow: 0px 26px 30px 0px rgba(0, 0, 0, 0.05);
  background-color: var(--Primary-Color-White, #fff);
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  justify-content: center;
  padding: 10px 20px;
`;

const DefaultPolisDiv = styled.div`
  display: flex;
  gap: 0px;
`;

const PolisText = styled.p`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: var(--Primary-Color-Black, #000);
  text-overflow: ellipsis;
  font-family: Open Sans, sans-serif;

  span {
    color: var(--Primary-Color-Prudential-Red, #ed1b2e);
    text-align: center;
    font-family: Open Sans, sans-serif;
  }
`;

const AddContribution = styled.div`
  justify-content: center;
  display: flex;
  margin-top: 20px;
  margin-bottom: 40px;
  gap: 6px;
  color: var(--Primary-Color-Black, #000);
  text-align: center;
  text-transform: capitalize;
  padding: 16px 68px;
  width: 100%;
  align-self: center;
  cursor: pointer;
`;

const AddContributionTitle = styled.div`
  font-family: Open Sans, sans-serif;
  font-weight: 600;
  font-size: 20px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const ImgAdd = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 18px;
`;

const BottomSeparator = styled.div`
  border: 1;
  height: 1px;
  width: 100%;
  background-color: #d8d8d8;
  margin-top: 20px;
`;

const AdditionalPolis = styled.div`
  display: flex;
  gap: 20px;
  font-size: 18px;
  font-weight: 300;
`;

const AdditionalPolisContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ImgDelete = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 32px;
  margin: auto 0;
  cursor: pointer;
`;

const Rekening = styled.div`
  align-items: start;
  border-radius: 8px;
  box-shadow: 0px 26px 30px 0px rgba(0, 0, 0, 0.05);
  background-color: var(--Primary-Color-White, #fff);
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  text-align: start;
  justify-content: center;
  padding: 9px 20px;
`;

const RekeningBox = styled.div`
  display: flex;
  width: 165px;
  max-width: 100%;
  flex-direction: column;
`;

const RekeningDiv = styled.div`
  display: flex;
  gap: 0px;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
`;

const RekeningText = styled.div`
  color: var(--Primary-Color-Black, #000);
  font-family: Open Sans, sans-serif;

  span {
    color: var(--Primary-Color-Prudential-Red, #ed1b2e);
    font-family: Open Sans, sans-serif;
  }
`;

const RekeningDivValue = styled.div`
  color: var(--Primary-Color-Black, #000);
  font: 300 18px/156% Open Sans, sans-serif;
`;

const RekeningBank = styled.div`
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0px 26px 30px 0px rgba(0, 0, 0, 0.05);
  background-color: var(--Primary-Color-White, #fff);
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  color: var(--Primary-Color-Black, #000);
  text-align: start;
  padding: 21px 24px;
`;

const RekeningBankBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
`;

const StatusRekening = styled.div`
  display: flex;
  margin-top: 20px;
  padding-right: 20px;
  gap: 12px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  line-height: 156%;
`;

const StatusRekeningLabel = styled.div`
  color: var(--Primary-Black-primary, #000);
  font-family: Open Sans, sans-serif;
`;

const StatusRekeningValue = styled.div`
  color: var(--Primary-Prudential-red, #ed1b2e);
  font-family: Open Sans, sans-serif;
`;

const KonfirmasiRekeningLain = styled.div`
  display: flex;
  margin-top: 19px;
  flex-direction: column;
`;

const KonfirmasiRekeningLainBox = styled.div`
  align-items: start;
  border-radius: 8px;
  background-color: var(--Primary-White-primary, #fff);
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  justify-content: center;
  padding: 23px 20px;
`;

const KonfirmasiRekeningLainDiv = styled.div`
  display: flex;
  gap: 12px;
`;

const KonfirmasiRekeningLainCheckboxDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;
`;

const KonfirmasiRekeningLainCheckbox = styled.div`
  border-radius: 4px;
  border-color: rgba(238, 238, 238, 1);
  border-style: solid;
  border-width: 2px;
  background-color: var(--Primary-White-primary, #fff);
  width: 24px;
  height: 24px;
`;

const KonfirmasiRekeningLainText = styled.div`
  color: #000;
  text-align: center;
  font: 600 18px/156% Open Sans, sans-serif;
`;

const UnggahDokumen = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  color: var(--Primary-Color-Black, #000);
  font-weight: 300;
  line-height: 156%;
  width: 100%;
  margin-top: 40px;
`;

const UnggahDokumenUploaded = styled.div`
  disply: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  display: flex;
  aspect-ratio: 2.04;
  font-size: 10px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  padding: 17px 8px;
  border-radius: 10px;
  margin-bottom: 20px;
`;

const UnggahDokumenUploadedImg = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const UnggahDokumenUploadedIcon = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 38px;
`;

const UnggahDokumenUploadedText = styled.div`
  position: relative;
  font-family: SF Pro Text, sans-serif;
  justify-content: center;
  border-radius: 24.943px;
  background-color: var(--Primary-Prudential-red, #ed1b2e);
  margin-top: 9px;
  padding: 12px 21px;
`;

const UnggahDokumenUpload = styled.div`
  justify-content: center;
  border-radius: 12px;
  background-color: var(--Primary-Blue-grey, #e5eaef);
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.3px;
  padding: 20px 10px;
  cursor: pointer;
`;

const UnggahDokumenUploadIcon = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 60px;
  align-self: center;
`;

const UnggahDokumenUploadText = styled.div`
  font-family: SF Pro Text, sans-serif;
  margin-top: 10px;
`;

const CheckedIcon = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 100%;
`;
