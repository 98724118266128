import {
  Box,
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PinImg from "../../assets/images/pin-img.jpg";
import InfoCircle from "../../assets/SVGIcon/info-circle.svg";
import Language from "../../components/Language";
import { useAuth } from "../../hooks/useAuth";
import { useAxios } from "../../hooks/useAxios";
import { aesDecrypt, aesEncrypt } from "../../hooks/useLocalStorage";
import styles from "./Login.module.css";
const SITE_KEY = "6LcddxkpAAAAAJZFSxIyuKBCeqU90lLPav8VofiI";

export const NumberBox = ({
  fieldName,
  no,
  value = "",
  setValue,
  isFailed = false,
  setIsFailed,
  setErrorMessage,
  isMobile = false,
}) => {
  const handleSetValue = (e) => {
    setIsFailed(false);
    setErrorMessage("");
    setValue(e.target.value);
    const nextfield = document.querySelector(
      `input[name=pin-number-${no + 1}]`
    );
    if (nextfield !== null && e.target.value !== "") {
      nextfield.focus();
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Backspace") {
      const nextfield = document.querySelector(
        `input[name=pin-number-${no - 1}]`
      );
      if (nextfield !== null && value === "") {
        nextfield.focus();
      }
    }
    if (e.key === "ArrowRight") {
      const nextfield = document.querySelector(
        `input[name=pin-number-${no + 1}]`
      );
      if (nextfield !== null) {
        nextfield.focus();
      }
    }
    if (e.key === "ArrowLeft") {
      const nextfield = document.querySelector(
        `input[name=pin-number-${no - 1}]`
      );
      if (nextfield !== null) {
        nextfield.focus();
      }
    }
  };
  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "#ffffff",
        border: isFailed ? "1px solid #F04E1B" : "solid #d8d8d8",
        borderRadius: "4px",
        display: "flex",
        width: isMobile ? "41.67px" : "74.67px",
        height: isMobile ? "52px" : "65px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <TextField
        name={fieldName}
        value={value}
        onChange={handleSetValue}
        inputProps={{
          type: "password",
          maxlength: 1,
          inputmode: "numeric",
          style: {
            color: isFailed ? "#F04E1B" : "#333333",
            fontWeight: "Bold",
            fontFamily: "Helvetica",
            textAlign: "center",
          },
        }}
        sx={{
          "& fieldset": {
            border: "none",
            textAlign: "center",
          },
        }}
        onKeyDown={handleKeyDown}
      />
    </Box>
  );
};

export const PIN = () => {
  const language = window.localStorage.getItem("language") || "id";
  const { echo } = Language();
  const { state } = useLocation();
  const { logout } = useAuth();
  const user = JSON.parse(aesDecrypt(window.localStorage.getItem("user")));
  const clientWidth = document.documentElement.clientWidth;
  const deviceID = window.localStorage.getItem("deviceID");
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [number1, setNumber1] = useState("");
  const [number2, setNumber2] = useState("");
  const [number3, setNumber3] = useState("");
  const [number4, setNumber4] = useState("");
  const [number5, setNumber5] = useState("");
  const [number6, setNumber6] = useState("");
  const [isFailed, setIsFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [timer, setTimer] = useState(60);

  const startTimer = () => {
    setIsButtonDisabled(true);
    setTimer(60);

    const intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          clearInterval(intervalId);
          setIsButtonDisabled(false);
          localStorage.removeItem("timerStatus");
          return 0;
        }
      });
    }, 1000);

    localStorage.setItem(
      "timerStatus",
      JSON.stringify({ isButtonDisabled: true, timer: 60 })
    );

    setTimeout(() => {
      clearInterval(intervalId);
      setIsButtonDisabled(false);
      localStorage.removeItem("timerStatus");
    }, 60000);
  };

  useEffect(() => {
    const storedTimerStatus = localStorage.getItem("timerStatus");

    if (storedTimerStatus) {
      const { isButtonDisabled, timer } = JSON.parse(storedTimerStatus);

      if (timer > 0) {
        setIsButtonDisabled(isButtonDisabled);
        setTimer(timer);
        startTimer();
      } else {
        localStorage.removeItem("timerStatus");
        setIsButtonDisabled(false);
      }
    } else {
      setIsButtonDisabled(isButtonDisabled);
      setTimer(timer);
      startTimer();
    }

    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      function () {}
    );
  }, []);

  const validatePin = (pin) => {
    setLoading(true);
    if (window.location.pathname === "/create-pin") {
      if (pin.length < 6) {
        setIsFailed(true);
        setErrorMessage(echo("invalid-data"));
        setLoading(false);
        return;
      }
      if (!state?.confirmPin) {
        navigate("/create-pin", {
          state: {
            confirmPin: true,
            newPin: pin,
          },
        });
        setNumber1("");
        setNumber2("");
        setNumber3("");
        setNumber4("");
        setNumber5("");
        setNumber6("");
        setLoading(false);
        return;
      }
      if (state?.newPin !== pin) {
        setIsFailed(true);
        setErrorMessage(echo("pin-not-match"));
        setLoading(false);
        return;
      }
      useAxios({
        method: "post",
        url: "/pin-save",
        body: {
          pin,
        },
      }).then((response) => {
        if (response) {
          const { status, data } = response;
          if (status == 200) {
            if (data.resp_code == 0) {
              setNumber1("");
              setNumber2("");
              setNumber3("");
              setNumber4("");
              setNumber5("");
              setNumber6("");
              setLoading(false);
              navigate("/pin");
            } else {
              const responseError =
                language === "id"
                  ? data.resp_desc?.id
                  : data.resp_desc?.en;
              setIsFailed(true);
              setErrorMessage(responseError || echo("something-went-wrong"));
            }
          } else {
            if (status === 401) {
              alert(echo("session-expired"));
              logout();
              navigate("/login");
            } else {
              const responseError =
                language === "id"
                  ? data.resp_desc?.id
                  : data.resp_desc?.en;
              alert(responseError || echo("something-went-wrong"));
            }
          }
          setLoading(false);
        }
        setLoading(false);
      });
      return;
    }
    setIsFailed(false);
    useAxios({
      method: "post",
      url: "/pin-login",
      body: {
        pin,
      },
    }).then((response) => {
      if (response) {
        const { status, data } = response;
        user.session = data;
        window.localStorage.setItem("user", aesEncrypt(JSON.stringify(user)));
        if (status == 200) {
          if (data.resp_code == 0) {
            navigate("/");
          } else {
            const responseError =
              language === "id"
                ? data?.resp_desc?.id
                : data?.resp_desc?.en;
            setIsFailed(true);
            setErrorMessage(responseError);
          }
        } else {
          if (status === 401) {
            alert(echo("session-expired"));
            logout();
            navigate("/login");
          } else {
            const responseError =
              language === "id"
                ? data.resp_desc?.id
                : data.resp_desc?.en;
            alert(responseError || echo("something-went-wrong"));
          }
        }
        setLoading(false);
      }
      setLoading(false);
    });
  };

  const resetPin = async () => {
    const captcha = await grecaptcha.execute(SITE_KEY, { action: "submit" });
    let method="face";
    let purpose="pin";
    useAxios({
      method: "post",
      url: "/auth-request",
      body: {
        method, purpose, captcha
      },
    }).then((response) => {
      const { status, data } = response;
      if (status == 200) {
        if (data.resp_code == 0) {
          if (data?.liveness_url)
            window.location.replace(data?.liveness_url);
        } else {
          const responseError =
            language === "id"
              ? data.resp_desc?.id
              : data.resp_desc?.en;
          alert(responseError || "Sistem mengalami gangguan, mohon coba beberapa saat lagi.");
        }
      } else {
        if (status === 401) {
          alert(echo("session-expired"));
          logout();
          navigate("/login");
        } else {
          const responseError =
            language === "id"
              ? data.resp_desc?.id
              : data.resp_desc?.en;
          alert(responseError || echo("something-went-wrong"));
        }
      }
    });
  };

  // useEffect(() => {
  //   if (
  //     number1 !== "" &&
  //     number2 !== "" &&
  //     number3 !== "" &&
  //     number4 !== "" &&
  //     number5 !== "" &&
  //     number6 !== ""
  //   ) {
  //     setLoading(true);
  //     const otp = number1 + number2 + number3 + number4 + number5 + number6;
  //     validateOtp(otp);
  //   }
  // }, [number1, number2, number3, number4, number5, number6]);

  return clientWidth < 1200 ? (
    <Container
      sx={{ display: "flex", flexDirection: "row" }}
      maxWidth={false}
      disableGutters={true}
      minWidth="sm"
    >
      <div className={styles.container}>
        <div className={styles.div}>
          <Box
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              paddingY: "24px",
              backgroundColor: "#e5eaef",
              [theme.breakpoints.up("md")]: {
                width: "70vh",
              },
            })}
          >
            <Typography
              sx={(theme) => ({
                color: "#333333",
                fontFamily: "Open Sans-Bold, sans-serif",
                fontSize: "36px",
                lineHeight: "48px",
                fontWeight: 800,
                marginTop: "-1px",
                [theme.breakpoints.up("md")]: {
                  fontSize: "38px",
                  lineHeight: "48px",
                },
              })}
            >
              {window.location.pathname === "/create-pin"
                ? echo(state?.confirmPin ? "confirm" : "create")
                : echo("enter")}{" "}
            </Typography>
            <Typography
              sx={(theme) => ({
                color: "#333333",
                fontFamily: "Open Sans, sans-serif",
                fontWeight: "lighter",
                fontSize: "36px",
                marginTop: "-1px",
                [theme.breakpoints.up("md")]: {
                  fontSize: "38px",
                  lineHeight: "48px",
                },
              })}
            >
              {window.location.pathname === "/create-pin"
                ? echo("security-pin")
                : echo("your-pin")}
            </Typography>
          </Box>
          {loading ? (
            <CircularProgress style={{ marginTop: "20px" }} />
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "40px",
              }}
            >
              <Typography
                sx={{ fontWeight: 700, marginBottom: "18px", fontSize: "19px" }}
              >
                {window.location.pathname === "/create-pin"
                  ? echo(state?.confirmPin ? "confirm" : "create")
                  : echo("enter")}{" "}
                {echo("6-digit-security-pin")}
              </Typography>
              <Box
                sx={(theme) => ({
                  alignItems: "flex-start",
                  justifyContent: "center",
                  display: "inline-flex",
                  gap: "4px",
                  flexDirection: "row",
                  [theme.breakpoints.up("md")]: {
                    width: "70vh",
                  },
                })}
              >
                <NumberBox
                  value={number1}
                  setValue={setNumber1}
                  fieldName="pin-number-1"
                  no={1}
                  isFailed={isFailed}
                  setIsFailed={setIsFailed}
                  setErrorMessage={setErrorMessage}
                  isMobile={true}
                />
                <NumberBox
                  value={number2}
                  setValue={setNumber2}
                  fieldName="pin-number-2"
                  no={2}
                  isFailed={isFailed}
                  setIsFailed={setIsFailed}
                  setErrorMessage={setErrorMessage}
                  isMobile={true}
                />
                <NumberBox
                  value={number3}
                  setValue={setNumber3}
                  fieldName="pin-number-3"
                  no={3}
                  isFailed={isFailed}
                  setIsFailed={setIsFailed}
                  setErrorMessage={setErrorMessage}
                  isMobile={true}
                />
                <NumberBox
                  value={number4}
                  setValue={setNumber4}
                  fieldName="pin-number-4"
                  no={4}
                  isFailed={isFailed}
                  setIsFailed={setIsFailed}
                  setErrorMessage={setErrorMessage}
                  isMobile={true}
                />
                <NumberBox
                  value={number5}
                  setValue={setNumber5}
                  fieldName="pin-number-5"
                  no={5}
                  isFailed={isFailed}
                  setIsFailed={setIsFailed}
                  setErrorMessage={setErrorMessage}
                  isMobile={true}
                />
                <NumberBox
                  value={number6}
                  setValue={setNumber6}
                  fieldName="pin-number-6"
                  no={6}
                  isFailed={isFailed}
                  setIsFailed={setIsFailed}
                  setErrorMessage={setErrorMessage}
                  isMobile={true}
                />
              </Box>

              {window.location.pathname !== "/create-pin" && (
                <Typography
                  sx={(theme) => ({
                    fontWeight: 600,
                    marginTop: "12px",
                    marginBottom: "16px",
                    fontSize: "16px",
                    alignSelf: "center",
                    [theme.breakpoints.up("lg")]: {
                      display: "none",
                    },
                  })}
                  onClick={resetPin}
                >
                  Lupa PIN?{" "}
                  <span style={{ color: "red", cursor: "pointer" }}>
                    {" "}
                    Klik disini{" "}
                  </span>
                </Typography>
              )}
              {isFailed && (
                <Typography
                  sx={{
                    fontFamily: "Helvetica",
                    fontSize: "14px",
                    fontWeight: 300,
                    lineHeight: "22px",
                    color: "#F04E1B",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    display: "inline-flex",
                    marginTop: 2,
                  }}
                >
                  <img
                    style={{ marginRight: 4, textAlign: "center" }}
                    src={InfoCircle}
                  />
                  {errorMessage}
                </Typography>
              )}
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "20px",
              marginTop: 5,
            }}
          >
            <Button
              sx={(theme) => ({
                fontStyle: "normal",
                fontFamily: "Open Sans-Bold, Helvetica",
                textTransform: "none",
                display: "flex",
                alignItems: "flex-start",
                alignSelf: "flex-start",
                gap: "10px",
                justifyContent: "center",
                padding: "4px 24px",
                cursor: "pointer",
                borderColor: "#f7f7f7",
                backgroundColor: "#ed1a2d",
                borderRadius: "100px",
                "&:hover": {
                  backgroundColor: "#ed1a2d",
                  boxShadow: "0 12px 8px -2px #D3d3d3",
                },
                [theme.breakpoints.up("md")]: {
                  width: "50vh",
                },
              })}
              className={styles.text_wrapper_5}
              onClick={() => {
                const pin =
                  number1 + number2 + number3 + number4 + number5 + number6;
                validatePin(pin);
              }}
            >
              {echo("continue")}
            </Button>
          </Box>
        </div>
      </div>
    </Container>
  ) : (
    <Container maxWidth={false} disableGutters={true} minWidth="sm">
      <Box
        sx={(theme) => ({
          flex: 1,
          backgroundColor: "#f7f7f7",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          paddingBottom: "20vh",
          [theme.breakpoints.up("lg")]: {
            flex: 1,
            backgroundColor: "#f7f7f7",
            alignItems: "center",
            display: "flex",
            paddingTop: 0,
            height: "100vh",
          },
        })}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            [theme.breakpoints.up("lg")]: {
              flex: 3,
              flexDirection: "row",
              justifyContent: "center",
              margin: 10,
            },
          })}
        >
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              [theme.breakpoints.up("lg")]: {
                flex: 2,
                alignItems: "flex-start",
              },
            })}
          >
            <Box
              sx={(theme) => ({
                alignItems: "flex-start",
                display: "flex",
                flexDirection: "column",
                gap: "6px",
                marginY: "20px",
                [theme.breakpoints.down("lg")]: {
                  alignItems: "left",
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  marginTop: "45px",
                },
              })}
            >
              <Typography
                sx={(theme) => ({
                  fontWeight: 700,
                  fontSize: "40px",
                  fontFamily: "Open Sans-Regular, Helvetica",
                  lineHeight: "32px",
                  [theme.breakpoints.up("lg")]: {
                    fontSize: "38px",
                    lineHeight: "48px",
                  },
                })}
                variant="h4"
              >
                {window.location.pathname === "/create-pin"
                  ? echo(state?.confirmPin ? "confirm" : "create")
                  : echo("enter")}{" "}
                <span
                  style={{
                    fontWeight: 100,
                    fontSize: "40px",
                    fontFamily: "Open Sans-Regular, Helvetica",
                    lineHeight: "32px",
                  }}
                >
                  {window.location.pathname === "/create-pin"
                    ? echo("security-pin")
                    : echo("your-pin")}
                </span>
              </Typography>
              {clientWidth > 1200 ? (
                <Typography
                  sx={{ marginBottom: "4px", width: 450, marginTop: "2px" }}
                >
                  {window.location.pathname === "/create-pin"
                    ? echo(state?.confirmPin ? "confirm" : "create")
                    : echo("enter")}{" "}
                  {echo("6-digit-security-pin")}
                </Typography>
              ) : null}
            </Box>
            <Box
              sx={(theme) => ({
                alignItems: "flex-start",
                display: "inline-flex",
                gap: "18px",
                flexDirection: "row",
                marginBottom: "20px",
                [theme.breakpoints.up("md")]: {
                  width: "36vw",
                },
              })}
            >
              <NumberBox
                value={number1}
                setValue={setNumber1}
                fieldName="pin-number-1"
                no={1}
                isFailed={isFailed}
                setIsFailed={setIsFailed}
                setErrorMessage={setErrorMessage}
              />
              <NumberBox
                value={number2}
                setValue={setNumber2}
                fieldName="pin-number-2"
                no={2}
                isFailed={isFailed}
                setIsFailed={setIsFailed}
                setErrorMessage={setErrorMessage}
              />
              <NumberBox
                value={number3}
                setValue={setNumber3}
                fieldName="pin-number-3"
                no={3}
                isFailed={isFailed}
                setIsFailed={setIsFailed}
                setErrorMessage={setErrorMessage}
              />
              <NumberBox
                value={number4}
                setValue={setNumber4}
                fieldName="pin-number-4"
                no={4}
                isFailed={isFailed}
                setIsFailed={setIsFailed}
                setErrorMessage={setErrorMessage}
              />
              <NumberBox
                value={number5}
                setValue={setNumber5}
                fieldName="pin-number-5"
                no={5}
                isFailed={isFailed}
                setIsFailed={setIsFailed}
                setErrorMessage={setErrorMessage}
              />
              <NumberBox
                value={number6}
                setValue={setNumber6}
                fieldName="pin-number-6"
                no={6}
                isFailed={isFailed}
                setIsFailed={setIsFailed}
                setErrorMessage={setErrorMessage}
              />
            </Box>
            {window.location.pathname !== "/create-pin" && (
              <Typography
                sx={(theme) => ({
                  fontWeight: 600,
                  marginTop: "12px",
                  marginBottom: "16px",
                  fontSize: "16px",
                  alignSelf: "flex-start",
                  [theme.breakpoints.down("md")]: {
                    display: "none",
                  },
                })}
                onClick={resetPin}
              >
                Lupa PIN?{" "}
                <span style={{ color: "red", cursor: "pointer" }}>
                  {" "}
                  Klik disini{" "}
                </span>
              </Typography>
            )}
            {isFailed && (
              <Typography
                sx={{
                  fontFamily: "Helvetica",
                  fontSize: "14px",
                  fontWeight: 300,
                  lineHeight: "22px",
                  color: "#F04E1B",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  display: "inline-flex",
                  marginTop: 2,
                  marginBottom: 2,
                }}
              >
                <img
                  style={{ marginRight: 4, textAlign: "center" }}
                  src={InfoCircle}
                />
                {errorMessage}
              </Typography>
            )}
            {loading ? (
              <CircularProgress style={{ marginTop: "20px" }} />
            ) : (
              <Button
                sx={(theme) => ({
                  fontStyle: "normal",
                  fontFamily: "Open Sans-Bold, Helvetica",
                  textTransform: "none",
                  display: "flex",
                  alignItems: "flex-start",
                  alignSelf: "flex-start",
                  gap: "10px",
                  justifyContent: "center",
                  padding: "8px 24px",
                  width: "180px",
                  cursor: "pointer",
                  borderColor: "#f7f7f7",
                  backgroundColor: "#ed1a2d",
                  borderRadius: "100px",
                  [theme.breakpoints.down("lg")]: {
                    width: "100%",
                  },
                  "&:hover": {
                    backgroundColor: "#ed1a2d",
                    boxShadow: "0 12px 8px -2px #D3d3d3",
                    [theme.breakpoints.down("lg")]: {
                      width: "100%",
                    },
                  },
                })}
                onClick={() => {
                  const pin =
                    number1 + number2 + number3 + number4 + number5 + number6;
                  validatePin(pin);
                }}
              >
                <Typography
                  sx={{
                    color: "#ffffff",
                    fontFamily: "Open Sans-Bold, Helvetica",
                    fontWeight: "700",
                    fontSize: "16px",
                  }}
                >
                  {echo("continue")}
                </Typography>
              </Button>
            )}
          </Box>
          {clientWidth > 1200 ? (
            <Box
              sx={(theme) => ({
                display: "flex",
                justifyContent: "flex-end",
                height: "32vh",
                width: "620px",
                backgroundColor: "#e5eaef",
                display: "hidden",
                marginRight: "-500px",
                marginTop: "35px",
              })}
            />
          ) : null}
          <Box
            sx={(theme) => ({
              flex: 1,
              marginTop: "70px",
              display: {
                xs: "none",
                lg: "block",
              },
            })}
          >
            <img
              height="400px"
              width="500px"
              style={{
                width: "500px",
                height: "400px",
                objectFit: "cover",
              }}
              src={PinImg}
            />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default PIN;
