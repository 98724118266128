import React, { useEffect, useState } from "react";
import { AnswerBox } from "../../components/AnswerBox";
import styles from "./Questionnaire.module.css";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAxios } from "@pru-cnp/ui-polisku";
import { useAuth, aesDecrypt } from "@pru-cnp/ui-polisku";

import mobileLogo from "../../assets/Icon/pru-icon.png";

import q1 from "../../assets/SVGIcon/q1.svg";
import q2 from "../../assets/SVGIcon/q2.svg";
import q3 from "../../assets/SVGIcon/q3.svg";
import q4 from "../../assets/SVGIcon/q4.svg";
import q5 from "../../assets/SVGIcon/q5.svg";

import q1a1 from "../../assets/SVGIcon/q1a1.svg";
import q1a2 from "../../assets/SVGIcon/q1a2.svg";
import q1a3 from "../../assets/SVGIcon/q1a3.svg";

import q2a1 from "../../assets/SVGIcon/q2a1.svg";
import q2a2 from "../../assets/SVGIcon/q2a2.svg";
import q2a3 from "../../assets/SVGIcon/q2a3.svg";

import q3a1 from "../../assets/SVGIcon/q3a1.svg";
import q3a2 from "../../assets/SVGIcon/q3a2.svg";
import q3a3 from "../../assets/SVGIcon/q3a3.svg";

import q4a1 from "../../assets/SVGIcon/q4a1.svg";
import q4a2 from "../../assets/SVGIcon/q4a2.svg";
import q4a3 from "../../assets/SVGIcon/q4a3.svg";

import q5a1 from "../../assets/SVGIcon/q5a1.svg";
import q5a2 from "../../assets/SVGIcon/q5a2.svg";
import q5a3 from "../../assets/SVGIcon/q5a3.svg";

const questionIcon = [
  { question: q1, a: q1a1, b: q1a2, c: q1a3 },
  { question: q2, a: q2a1, b: q2a2, c: q2a3 },
  { question: q3, a: q3a1, b: q3a2, c: q3a3 },
  { question: q4, a: q4a1, b: q4a2, c: q4a3 },
  { question: q5, a: q5a1, b: q5a2, c: q5a3 },
];

// Function to get JsonObject by Index
function getJsonObjectByIndex(obj, index) {
  return obj[Object.keys(obj)[index-1]];
}

export const Questionnaire = () => {
  const { logout } = useAuth();
  const deviceID = window.localStorage.getItem("deviceID");
  const userStorage = window.localStorage.getItem("user");
  const user =
    !userStorage || userStorage === "null"
      ? userStorage
      : JSON.parse(aesDecrypt(window.localStorage.getItem("user")));
  const navigate = useNavigate();
  const [questionData, setQuestionData] = useState({});
  const [currentNo, setCurrentNo] = useState(
    JSON.parse(window.localStorage.getItem("currentNo")) || 1
  );
  const [currentQuestion, setCurrentQuestion] = useState(
      {"question":"","answer":[{"description":"","weight":0},{"description":"","weight":1},{"description":"","weight":2}]}
  );
  const [results, setResults] = useState(
    JSON.parse(window.localStorage.getItem("results")) || []
  );
  const handleBack = () => {
    if (currentNo - 1 < 1) navigate("/");
    setCurrentNo(currentNo - 1);
  };

  const handleOnAnswer = (weight) => {
    let isExist = false;
    const newResults = [...results];
    const aidi=Object.keys(questionData)[currentNo-1];
    newResults.map((result) => {
      if (result.id === aidi) {
        isExist = true;
        return (result.weight = weight);
      }
    });

    if (!isExist) newResults.push({ id: aidi, weight });

    setResults(newResults);
    setCurrentNo(currentNo + 1);
    localStorage.setItem("results", JSON.stringify(results));
  };

  useEffect(() => {
    localStorage.setItem("results", JSON.stringify(results));
  }, [results]);

  useEffect(() => {
    useAxios({
      method: "get",
      url: `/rpq/list`,
    }).then((response) => {
      if (response?.status == 200) {
        setQuestionData(response?.data?.data);
      } else if (response?.status == 403 || response?.status === 401) {
        logout();
      }
    });
  }, []);

  useEffect(() => {
    if (questionData.length !== 0) {
      setCurrentQuestion(getJsonObjectByIndex(questionData,currentNo));
    }
  }, [questionData]);

  useEffect(() => {
    if (currentNo > 5) {
      useAxios({
        method: "post",
        url: "/rpq/assessment",
        body: {
          result: results,
        },
      }).then((response) => {
        if (response?.status == 200) {
          if (response?.data?.resp_code == 0) {
            localStorage.removeItem("currentNo");
            localStorage.removeItem("results");
            const currentResult = response.data?.assessment_result;
            const previousResult = response.data?.previous_result;

            if (currentResult?.recomended_fund_level === "L")
              currentResult.assessment_result = "Konservatif";
            if (currentResult?.recomended_fund_level === "M")
              currentResult.assessment_result = "Moderat";
            if (currentResult?.recomended_fund_level === "H")
              currentResult.assessment_result = "Agresif";

            if (previousResult?.recomended_fund_level === "L")
              previousResult.assessment_result = "Konservatif";
            if (previousResult?.recomended_fund_level === "M")
              previousResult.assessment_result = "Moderat";
            if (previousResult?.recomended_fund_level === "H")
              previousResult.assessment_result = "Agresif";

            const assessmentResults = {
              currentResult: response.data?.assessment_result,
              previousResult: response.data?.previous_result,
            };
            localStorage.setItem(
              "assessmentResults",
              JSON.stringify(assessmentResults)
            );
            navigate("/risk-profile/result");
            setCurrentNo(1);
          } else {
            alert(response?.data?.resp_desc || "Terjadi kesalahan.");
            setCurrentNo(5);
          }
        } else {
          if (response?.status === 403 || response?.status === 401) {
            logout();
          }
          alert(response?.data?.resp_desc || "Terjadi kesalahan.");
          setCurrentNo(5);
        }
      });
    } else {
      if (questionData.length !== 0)
        setCurrentQuestion(getJsonObjectByIndex(questionData,currentNo));
    }
    localStorage.setItem("currentNo", JSON.stringify(currentNo));
  }, [currentNo]);

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: "#f7f7f7",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "100%",
        paddingBottom: "30vh",
        height: "100%",
        [theme.breakpoints.up("lg")]: {
          height: "100vh",
        },
      })}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          alignSelf: "center",
          marginTop: "24px",
          width: "300px",
          [theme.breakpoints.up("md")]: {
            width: "80vw",
          },
        })}
      >
        <div className={styles.nav}>
          <div className={styles.frame_5}>
            <Typography
              sx={{
                display: currentNo > 5 ? "none" : "block",
                color: "#000000",
                fontFamily: "Noto Sans-Bold, Helvetica",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "24px",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={handleBack}
            >
              Kembali
            </Typography>
          </div>
        </div>
      </Box>
      {questionData.length !== 0 && currentNo <= 5 ? (
        <Box
          sx={(theme) => ({
            display: "flex",
            alignSelf: "center",
            width: "300px",
            [theme.breakpoints.up("md")]: {
              width: "60vw",
            },
          })}
        >
          <div className={styles.frame_2}>
            <Box
              sx={(theme) => ({
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                gap: "8px",
                width: "300px",
                [theme.breakpoints.up("md")]: {
                  width: "60vw",
                },
              })}
            >
              <Box
                sx={(theme) => ({
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "300px",
                  [theme.breakpoints.up("md")]: {
                    width: "60vw",
                  },
                })}
              >
                <Typography
                  sx={(theme) => ({
                    color: "#808080",
                    fontFamily: "Open Sans-Bold, Helvetica",
                    fontSize: "12px",
                    lineHeight: "18px",
                    fontWeight: 400,
                    [theme.breakpoints.up("md")]: {
                      fontSize: "14px",
                      lineHeight: "22px",
                    },
                  })}
                >
                  Pertanyaan
                </Typography>
                <Typography
                  sx={(theme) => ({
                    color: "#333333",
                    fontFamily: "Open Sans-Bold, Helvetica",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "18px",
                    textAlign: "right",
                    [theme.breakpoints.up("md")]: {
                      fontSize: "14px",
                      lineHeight: "22px",
                    },
                  })}
                >
                  <span className={styles.current_no}>{currentNo}</span> dari 5
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "12px",
                  alignItems: currentNo === 2 ? "center" : "flex-start",
                }}
              >
                <img src={questionIcon[currentNo - 1].question} />
                <Typography
                  sx={(theme) => ({
                    color: "#000000",
                    fontFamily: "Open Sans-Regular, Helvetica",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "20px",
                    width: "235px",
                    [theme.breakpoints.up("md")]: {
                      fontSize: "20px",
                      lineHeight: "28px",
                      width: "55vw",
                    },
                  })}
                >
                  {currentQuestion?.question}
                </Typography>
              </Box>
              <Typography
                sx={(theme) => ({
                  color: "#808080",
                  fontFamily: "Open Sans-Bold, Helvetica",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "18px",
                  margin: "10px 0 10px",
                  [theme.breakpoints.up("md")]: {
                    fontSize: "14px",
                    lineHeight: "22px",
                  },
                })}
              >
                Pilih Jawaban
              </Typography>
            </Box>
            <Box
              sx={(theme) => ({
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                gap: "8px",
                width: "300px",
                [theme.breakpoints.up("md")]: {
                  width: "60vw",
                },
              })}
            >
              <AnswerBox
                onAnswer={() =>
                  handleOnAnswer(currentQuestion?.answer[0]?.weight)
                }
                answerOption={currentQuestion?.answer[0]?.description}
                answerIcon={questionIcon[currentNo - 1].a}
              />
              <AnswerBox
                onAnswer={() =>
                  handleOnAnswer(currentQuestion?.answer[1]?.weight)
                }
                answerOption={currentQuestion?.answer[1]?.description}
                answerIcon={questionIcon[currentNo - 1].b}
              />
              <AnswerBox
                onAnswer={() =>
                  handleOnAnswer(currentQuestion?.answer[2]?.weight)
                }
                answerOption={currentQuestion?.answer[2]?.description}
                answerIcon={questionIcon[currentNo - 1].c}
              />
            </Box>
          </div>
        </Box>
      ) : (
        <Box sx={{ display: "flex", alignSelf: "center", height: "100vh" }}>
          <CircularProgress
            sx={{ display: "flex", alignSelf: "center", height: "100vh" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default Questionnaire;
