import styled from "@emotion/styled";
import { Box, Button, Typography } from "@mui/material";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { aesEncrypt, LanguagePolisku, useAxios } from "@pru-cnp/ui-polisku";
import * as BuilderOCR from "@verihubs/ocr";

import KTPIImg from "../assets/ktp-i-img.jpg";
import { instructionKTP, makeSureKTP } from "./KTPIcon";

import instruction1 from "../../public/sdk/ocr/assets/il_instruction_rule_1.svg";
import instruction2 from "../../public/sdk/ocr/assets/il_instruction_rule_2.svg";
import instruction3 from "../../public/sdk/ocr/assets/il_instruction_rule_3.svg";

import { EKYC_COMMON_HOST } from "../root.component";

export const STEP_KTP = {
  1: "KTP",
  2: "KTP_I",
  3: "OCR",
  4: "KTP_R",
};

function KTPStep({ kycStep, handleNext }) {
  const stringParams = document.location.search;
  const searchParams = new URLSearchParams(stringParams);
  let lang = searchParams.get("language");
  let onboard_id = searchParams.get("onboard-id");
  const [language] = React.useState(
    lang || window.localStorage.getItem("language") || "id"
  );
  const [onBoardId] = React.useState(
    onboard_id || window.localStorage.getItem("onBoardId")
  );
  const EKYC_COMMON_HEADERS = {
    id: `${onBoardId}`,
  };
  const { echo } = LanguagePolisku();
  const navigate = useNavigate();
  const { state } = useLocation();

  React.useEffect(() => {
    let OCR_SDK_URL = "https://polisku-uat.prudential.co.id/ui-ekyc/sdk/ocr";
    if (process.env.NODE_ENV === "production")
      OCR_SDK_URL = "https://polisku.prudential.co.id/ui-ekyc/sdk/ocr";
    if (process.env.NODE_ENV === "development")
      OCR_SDK_URL = "https://polisku-uat.prudential.co.id/ui-ekyc/sdk/ocr";

    if (STEP_KTP[kycStep] === "KTP") {
      useAxios({
        method: "get",
        override: {
          title: "ekyc-get-onboard-detail",
          url: `${EKYC_COMMON_HOST}/onboard-token/${onBoardId}`,
        },
      }).then((response) => {
        if (response) {
          const { status, data } = response;
          if (status == 200) {
            if (data.token) {
              const userEkyc = {
                email: data.email,
                phone: data.phone,
                redirect_url: data.redirect_url,
                onBoardId: onBoardId,
                token: data.token,
              };
              window.localStorage.setItem(
                "userEkyc",
                aesEncrypt(JSON.stringify(userEkyc))
              );
              EKYC_COMMON_HEADERS.Authorization = `Bearer ${data.token}`;

              try {
                window.OCRSDK = new BuilderOCR()
                  .setAttemptCount(3)
                  .setI18n({
                    defaultLang: language,
                    showLanguageSelect:false
                  })
                  .setProxyMiddleware({
                    Extract: {
                      url: `${EKYC_COMMON_HOST}/ktp-extract`,
                      headers: EKYC_COMMON_HEADERS,
                    },
                    ExtractSync: {
                      url: `${EKYC_COMMON_HOST}/synch/ktp`,
                      headers: EKYC_COMMON_HEADERS,
                    },
                    ExtractResult: {
                      url: `${EKYC_COMMON_HOST}/ktp-result`,
                      headers: EKYC_COMMON_HEADERS,
                    },
                    License: {
                      url: `${EKYC_COMMON_HOST}/ocr-license`,
                      headers: EKYC_COMMON_HEADERS,
                    },
                    Correction: {
                      url: `${EKYC_COMMON_HOST}/ktp-correction`,
                      headers: EKYC_COMMON_HEADERS,
                    },
                  })
                  .setTheme({
                    Component: {
                      Instruction: {
                        action: {
                          start: {
                            backgroundColor: "red",
                            hover: {
                              backgroundColor: "red",
                              boxShadow: "0 4px 24px -2px #D3d3d3",
                            },
                          },
                        },
                      },
                      Capture: {
                        action: {
                          takePhoto: {
                            backgroundColor: "red",
                            hover: {
                              backgroundColor: "red",
                              boxShadow: "0 4px 24px -2px #D3d3d3",
                            },
                            disabled: {
                              backgroundColor: "#FF7F7F",
                            },
                          },
                        },
                      },
                      Check: {
                        action: {
                          continue: {
                            backgroundColor: "red",
                            borderColor: "red",
                            hover: {
                              backgroundColor: "red",
                              boxShadow: "0 4px 24px -2px #D3d3d3",
                            },
                          },
                        },
                      },
                      Form: {
                        action: {
                          submit: {
                            backgroundColor: "red",
                            hover: {
                              backgroundColor: "red",
                              boxShadow: "0 4px 24px -2px #D3d3d3",
                            },
                            disabled: {
                              backgroundColor: "#FF7F7F",
                            },
                          },
                        },
                      },
                      ConfirmationDialog: {
                        action: {
                          ok: {
                            backgroundColor: "red",
                            borderColor: "red",
                            hover: {
                              backgroundColor: "red",
                              boxShadow: "0 4px 24px -2px #D3d3d3",
                            },
                          },
                        },
                      },
                    },
                  })
                  .setURL(OCR_SDK_URL)
                  .setContent({
                    Instruction: {
                      title: language=="id"?"Validasi Kartu Tanda Penduduk (KTP)": "Identity Card Validation (KTP)",
                      subtitle: language=="id"?"Silahkan mengikuti instruksi dibawah.":"Please follow the instructions below.",
                      instructions: [
                        {
                          caption:
                            language=="id"?"Pengambilan foto KTP harus jelas, Anda mempunyai 3 kesempatan untuk kirim foto":"The photo of the ID card must be clear, you have 3 chances to send the photo",
                          image: instruction1,
                        },
                        {
                          caption:
                            language=="id"?"Saat pengambilan foto pastikan fisik KTP ada didalam frame yang disediakan, jelas dan tidak terpotong":"When taking a photo, make sure the physical ID card is inside the provided frame, clear and not cut off",
                          image: instruction2,
                        },
                        {
                          caption:
                            language=="id"?"Mohon dipastikan kembali data KTP sebelum melanjutkan proses e-kyc":"Please make sure the KTP data before continuing the e-kyc process",
                          image: instruction3,
                        },
                      ],
                      action: {
                        start: language=="id"?"Mulai Sekarang":"Start Now",
                        next: language=="id"?"Lanjut":"Next",
                      },
                    },
                    Capture: {
                      description:
                        language=="id"?"Saat pengambilan foto pastikan fisik KTP ada didalam frame yang disediakan, jelas dan tidak terpotong.":"When taking a photo, make sure the physical ID card is inside the provided frame, clear and not cut off.",
                      action: {
                        takePhoto: language=="id"?"Ambil Gambar":"Take Picture",
                      },
                    },
                    Check: {
                      title: language=="id"?"Pengecekan gambar":"Image Check",
                      message:
                        language=="id"?"Saat pengambilan foto pastikan fisik KTP ada didalam frame yang disediakan, jelas dan tidak terpotong.":"When taking a photo, make sure the physical ID card is inside the provided frame, clear and not cut off.",
                      action: {
                        continue: language=="id"?"Lanjutkan":"Continue",
                        retake: language=="id"?"Ambil ulang gambar KTP":"Retake Picture",
                      },
                    },
                    CheckFailed: {
                      title: language=="id"?"Hasil Pengecekan":"Check Result",
                      message:
                        language=="id"?"Mohon maaf pengambilan foto KTP tidak terlihat jelas. Silakan ulangi pengambilan foto.":"Sorry, the photo of the ID card is not clear. Please retake the photo.",
                      action: {
                        retake: language=="id"?"Ambil ulang gambar KTP":"Retake Picture",
                      },
                      infoMessage:
                        language=="id"?"Kesempatan anda untuk mengambil gambar KTP sudah dikurangi":"Your chance to take a picture of the ID card has been reduced",
                      End: {
                        action: {
                          exit: language=="id"?"Keluar": "Exit",
                        },
                      },
                    },
                    Form: {
                      title: language=="id"?"Validasi data KTP":"Identity Card Validation",
                      subtitle:
                        language=="id"?"Mohon pastikan data yang terisi sudah sesuai dengan KTP. Anda dapat memperbaiki data secara manual jika ada yang tidak sesuai dengan KTP Anda.":"Please make sure the data filled in is in accordance with the ID card. You can correct the data manually if it does not match your ID card.",
                      action: {
                        submit: language=="id"?"Kirim Sekarang":"Submit Now",
                      },
                      formLabels: {
                        full_name: language=="id"?"Nama Lengkap":"Full Name",
                        date_of_birth: language=="id"?"Tanggal Lahir":"Date of Birth",
                        place_of_birth: language=="id"?"Tempat Lahir":  "Place of Birth",
                      },
                    },
                    SubmissionDialog: {
                      title:
                        language=="id"?"Dengan ini saya menyatakan bahwa data yang saya berikan adalah asli dan sesuai dengan e-KTP":"I hereby declare that the data I provide is genuine and in accordance with the e-KTP",
                      okText: language=="id"?"Saya Setuju, Lanjutkan":"I Agree, Continue",
                      cancelText: language=="id"?"Batal":"Cancel",
                    },
                  })
                  .hideCorrectionField({
                    gender: true,
                    blood_type: true,
                    address: true,
                    rt_rw: true,
                    province: true,
                    city: true,
                    administrative_village: true,
                    district: true,
                    religion: true,
                    marital_status: true,
                    occupation: true,
                    nationality: true,
                  })
                  .build();
              } catch (error) {
                alert(error);
                navigate(-1);
              }
            } else {
              const responseError =
                language === "id"
                  ? data.resp_desc?.id
                  : data.resp_desc?.en;
              alert(responseError || language=="id"?"Sistem mengalami gangguan, mohon coba beberapa saat lagi.":"System is experiencing problems, please try again later.");
              window.localStorage.removeItem("userEkyc");
            }
          } else {
            const responseError =
              language === "id"
                ? data.resp_desc?.id
                : data.resp_desc?.en;
            alert(responseError || language=="id"?"Sistem mengalami gangguan, mohon coba beberapa saat lagi.":"System is experiencing problems, please try again later.");
            window.localStorage.removeItem("userEkyc");
          }
        }
      });
    }
    if (STEP_KTP[kycStep] === "OCR") {
      navigate("/ekyc/ocr");
    }
  }, [kycStep]);

  const renderBeforeOCR = (step) => {
    return (
      <Container>
        <Typography
          style={{
            fontFamily: "Ooen Sans-Bold, Helvetica",
            fontSize: "36px",
            lineHeight: "48px",
            fontWeight: 700,
          }}
        >
          {echo("take-picture")}
          <br />
          <span
            style={{
              color: "#ed1b2e",
            }}
          >
            {echo("of-your-id-card")}
          </span>
        </Typography>
        <Description>{echo("take-ktp-required")}</Description>
        <Subtitle>
          {step === "KTP_I" ? echo("please-make-sure") : echo("please-prepare")}
          :
        </Subtitle>
        {step === "KTP_I" && (
          <KTPWrapper>
            <img src={KTPIImg}></img>
          </KTPWrapper>
        )}
        {step === "KTP" ? (
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              gap: "20px",
              marginBottom: 3,
              [theme.breakpoints.down("lg")]: {
                flexDirection: "column",
              },
            })}
          >
            {makeSureKTP.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <IconWrapper>
                  <Icon src={item.icon} alt={`Icon ${index + 1}`} />
                </IconWrapper>
                <ListText>{echo(item.text)}</ListText>
              </Box>
            ))}
          </Box>
        ) : (
          <InstructionList>
            {instructionKTP.map((card, index) => (
              <InstructionListDiv key={index}>
                <InstructionIcon src={card.imageSrc} alt={card.title} />
                <InstructionContent>
                  <InstructionTitle>{echo(card.title)}</InstructionTitle>
                  {/* <InstructionDesc>{echo(card.description)}</InstructionDesc> */}
                </InstructionContent>
              </InstructionListDiv>
            ))}
          </InstructionList>
        )}
      </Container>
    );
  };

  const renderAfterOCR = () => {
    const dataKTP = [
      {
        label: "nik",
        value: state?.dataKTP?.nik,
      },
      {
        label: "name",
        value: state?.dataKTP?.full_name,
      },
      {
        label: "place-date-of-birth",
        value:
          state?.dataKTP?.place_of_birth + "," + state?.dataKTP?.date_of_birth,
      },
      // {
      //   label: "address",
      //   value: state?.dataKTP?.address,
      // },
      // {
      //   detailAddress: [
      //     {
      //       label: "RT/RW",
      //       value: state?.dataKTP?.rt_rw,
      //     },
      //     {
      //       label: "Kel/Desa",
      //       value: state?.dataKTP?.administrative_village,
      //     },
      //     {
      //       label: "Kecamatan",
      //       value: state?.dataKTP?.district,
      //     },
      //   ],
      // },
      // {
      //   label: "religion",
      //   value: state?.dataKTP?.religion,
      // },
      // {
      //   label: "marital-status",
      //   value: state?.dataKTP?.marital_status,
      // },
      // {
      //   label: "occupation",
      //   value: state?.dataKTP?.occupation,
      // },
      // {
      //   label: "citizenship",
      //   value: state?.dataKTP?.nationality,
      // },
    ];

    return (
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          [theme.breakpoints.up("lg")]: {
            paddingLeft: "240px",
            paddingRight: "240px",
          },
        })}
      >
        <div style={{ flex: 1 }}>
          <Box
            sx={(theme) => ({
              display: "block",
              [theme.breakpoints.down("lg")]: {
                display: "none",
              },
            })}
          >
            <Description>
              {echo("please-make-sure-data-matches-ID")}
            </Description>
            <ImageWrapper>
              <Image
                loading="lazy"
                src={state?.dataKTP?.image.url}
                alt="Descriptive alt text for the image"
              />
            </ImageWrapper>
          </Box>
          <Box
            sx={(theme) => ({
              display: "none",
              [theme.breakpoints.down("lg")]: {
                display: "block",
              },
            })}
          >
            <ImageWrapper>
              <Image
                loading="lazy"
                src={state?.dataKTP?.image.url}
                alt="Descriptive alt text for the image"
              />
            </ImageWrapper>
            <Description>
              {echo("please-make-sure-data-matches-ID")}
            </Description>
            {dataKTP?.map((item, index) => (
              <InfoCard key={index}>
                {index === 4 ? (
                  <>
                    {item?.detailAddress.map((item, index) => (
                      <AddressItem key={index}>
                        <InfoValue>{item.label}</InfoValue>
                        <InfoValue>{item.value}</InfoValue>
                      </AddressItem>
                    ))}
                  </>
                ) : (
                  <>
                    <InfoLabel>{echo(item.label)}</InfoLabel>
                    <InfoValue>{item.value}</InfoValue>
                  </>
                )}
              </InfoCard>
            ))}
            <Button
              onClick={handleNext}
              sx={{
                justifyContent: "center",
                borderRadius: "30px",
                backgroundColor: "var(--Primary-Color-Prudential-Red, #ED1B2E)",
                color: "var(--Primary-Color-White, #FFF)",
                whiteSpace: "nowrap",
                textAlign: "center",
                padding: "12px 22px",
                font: "600 12px/156% Open Sans, sans-serif",
                border: "none",
                cursor: "pointer",
                width: 100,
                textTransform: "none",
                marginTop: "40px",
                "&:hover": {
                  backgroundColor:
                    "var(--Primary-Color-Prudential-Red, #ED1B2E)",
                },
              }}
            >
              {echo("continue")}
            </Button>
          </Box>
        </div>
        <Box
          sx={(theme) => ({
            display: "flex",
            flex: 1,
            flexDirection: "column",
            [theme.breakpoints.down("lg")]: {
              display: "none",
            },
          })}
        >
          {dataKTP.map((item, index) => (
            <InfoCard key={index}>
              {index === 4 ? (
                <>
                  {item.detailAddress.map((item, index) => (
                    <AddressItem key={index}>
                      <InfoValue>{item.label}</InfoValue>
                      <InfoValue>{item.value}</InfoValue>
                    </AddressItem>
                  ))}
                </>
              ) : (
                <>
                  <InfoLabel>{echo(item.label)}</InfoLabel>
                  <InfoValue>{item.value}</InfoValue>
                </>
              )}
            </InfoCard>
          ))}
          <Button
            onClick={handleNext}
            sx={{
              justifyContent: "center",
              borderRadius: "30px",
              backgroundColor: "var(--Primary-Color-Prudential-Red, #ED1B2E)",
              color: "var(--Primary-Color-White, #FFF)",
              whiteSpace: "nowrap",
              textAlign: "center",
              padding: "12px 22px",
              font: "600 12px/156% Open Sans, sans-serif",
              border: "none",
              cursor: "pointer",
              width: 200,
              textTransform: "none",
              marginTop: "40px",
              "&:hover": {
                backgroundColor: "var(--Primary-Color-Prudential-Red, #ED1B2E)",
              },
            }}
          >
            {echo("continue")}
          </Button>
        </Box>
      </Box>
    );
  };

  if (STEP_KTP[kycStep] === "KTP" || STEP_KTP[kycStep] === "KTP_I")
    return renderBeforeOCR(STEP_KTP[kycStep]);
  return renderAfterOCR();
}

const Container = styled.div`
  display: flex;
  margin-right: 20px;
  margin-left: 4px;
  flex-direction: column;
`;

const Subtitle = styled.h2`
  color: #000;
  margin: 20px 0;
  width: 100%;
  font: 700 18px/156% Open Sans, sans-serif;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: var(--Primary-Prudential-red, #ed1b2e);
  padding: 8px;
`;

const Icon = styled.img`
  width: 14px;
  stroke: var(--Primary-White-primary, #);
  border: 0px solid rgba(255, 255, 255, 1);
`;

const ListText = styled.p`
  color: var(--Primary-Black-primary, #);
  margin: auto 0;
  font: 400 16px/150% Open Sans, sans-serif;

  .italic {
    font-style: italic;
  }
`;

const ImageWrapper = styled.div`
  align-self: stretch;
  max-width: 308px;
`;

const Image = styled.img`
  aspect-ratio: 1.54;
  object-fit: cover;
  object-position: center;
  width: 100%;
`;

const Description = styled.p`
  color: #000;
  width: 100%;
  font: 400 16px/24px Open Sans, sans-serif;
`;

const Highlight = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
`;

const KTPWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-self: stretch;
  border-radius: 11.424px;
  max-width: 308px;
  flex-direction: column;
  padding: 10px;

  .img {
    aspect-ratio: 1.52;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
`;

const InstructionList = styled.div`
  display: flex;
  flex-direction: column;
  color: #000;
`;

const InstructionListDiv = styled.div`
  display: flex;
`;

const InstructionIcon = styled.img`
  width: 30px;
`;

const InstructionContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
`;

const InstructionTitle = styled.h3`
  font: 700 14px/20px "Open Sans", sans-serif;
  margin: 0;
`;

const InstructionDesc = styled.p`
  font: 400 11px/1.38 "Open Sans", sans-serif;
  margin: 0;
`;

const AddressItem = styled.div`
  display: flex;
  gap: 2px;
  justify-content: space-between;
`;

const AddressValue = styled.div`
  font-family: Open Sans, sans-serif;
  align-self: flex-end;
`;

const NextButton = styled.button`
  display: flex;
  margin-top: 20px;
  padding-right: 30px;
  gap: 7px;
  font: 600 16px/28px Open Sans, sans-serif;
  text-align: center;
  border: none;
  cursor: pointer;
`;

const NextIcon = styled.img`
  aspect-ratio: 1.06;
  object-fit: auto;
  object-position center;
  width: 17px;
  margin: auto 0;
`;

const InfoCard = styled.div`
  border-radius: 8px;
  box-shadow: 0px 26px 30px 0px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  text-align: start;
  justify-content: center;
  padding: 9px 24px;
`;

const InfoLabel = styled.div`
  font: 700 14px/24px Open Sans, sans-serif;
`;

const InfoValue = styled.div`
  font: 300 18px/156% Open Sans, sans-serif;
`;

export default KTPStep;
